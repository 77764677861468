/* eslint-disable no-useless-concat */
// eslint-disable-next-line import/prefer-default-export
export const accountantsHero = {
  title: "POS for accounting services",
  subtext:
    "Expand your client base and keep them coming back with integrated payment, website, and loyalty tools to take your accounting business to the next level.",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/professional-services/accountants/demo",
  },
};
export const accountantsSubHero = {
  title: "Future-proof your firm",
  subtext:
    "You’ve invested considerable time and equity into your accounting business, but the world is changing rapidly. SpotOn is here to help you meet the changing expectations of your clients. ",
  list: [
    "Attract new clients with a strong online presence",
    "Boost client retention to grow revenue",
    "Streamline operations with better tools",
  ],
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/professional-services/accountants/demo",
  },
};

export const accountantsWhiteGlove = {
  mainTitle: "We are there for you & your customers",
  title: "The white-glove service \n" + "you deserve",
  content:
    "You provide the highest level of service to your clients to earn their trust. At SpotOn, we’ll do the same for you. Our team of experts will work hand-in-hand with you to assess your needs and create a tailored solution that helps you save time, grow revenue, and make your life a little easier. We’ll teach you how to get the most from your new tools, and we’ll continue to earn your business every day with local service and 24/7 support included.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/professional-services/accountants/demo",
  },
};

export const accountantsLargeFeatureA = {
  featureBlocks: [
    {
      blockTitle: "Simple technology to tackle complex problems",
      blockSubTitle:
        "You face dozens of challenges every day, but that doesn’t mean you want dozens of solutions. Simplify with SpotOn. Our cloud-based tools are thoughtfully designed to work in tandem, with a single login—saving you time, saving you money, and creating a seamless client experience.",
      blockImg: "olo-modified.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/professional-services/accountants/demo",
      },
    },
  ],
};

export const accountantsTrusted = [
  {
    title: "88%",
    subtext: "Of customers search online before visiting a business",
  },
  {
    title: "61%",
    subtext: "Of clients want to receive marketing emails",
  },
  {
    title: "31%",
    subtext: "Of repeat clients spend more than new clients",
  },
];

export const accountantsMultiSection = {
  sectionTitle: "Tailored solutions for accountants",
  featureBlocks: [
    {
      blockTitle: "Make payments & invoicing easy",
      blockSubTitle:
        "SpotOn Virtual Terminal gives you the flexibility to take payments in a way that’s convenient for you and your clients. Easily run credit card payments and deposits right from your computer or your phone, and so much more.",
      blockList: [
        "Online payments & deposits",
        "Custom invoices & reminders",
        "Recurring payments",
        "Secure payment portal for clients",
      ],
      blockImg: "accountant-a.png",
      forceReverse: false,
      ctaInfo: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/virtual-terminal",
      },
    },
    {
      blockTitle: "Redefine your presence online",
      blockSubTitle:
        "Why expend your valuable time trying to create your own website? Our specialists will build a stunning site for you at a do-it-yourself price to help you appear more prominently in online searches and attract more clients.",
      blockImg: "accountant-b.png",
      blockList: [
        "Custom website design",
        "Web hosting",
        "Custom web address",
        "Self-service dashboard",
        "Optional logo design",
        "Lifetime support",
      ],
      forceReverse: false,
      ctaInfo: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/e-commerce",
      },
    },
    {
      blockTitle: "Get discovered on review sites",
      blockSubTitle:
        "Know what people are saying about you on popular review sites like Yelp, TripAdvisor, Google, and Facebook. Our online review management software makes it easy to monitor all of your online reviews in one place and get automated alerts when new reviews are posted—so you can not only monitor your reputation, but also improve it.",
      blockImg: "accountant-c.png",
      forceReverse: false,
      ctaInfo: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/review-management",
      },
    },
    {
      blockTitle: "Deepen client relationships",
      blockSubTitle:
        "Provide the level of service that will attract new clients and incentivize repeat visits with SpotOn Loyalty, our streamlined digital loyalty rewards program that serves as your high-tech solution to turn one-time visitors into loyal clients, and accelerate revenue in the process.",
      blockImg: "accountant-d.png",
      forceReverse: false,
      ctaInfo: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/loyalty",
      },
    },
    {
      blockTitle: "Create marketing campaigns like a pro",
      blockSubTitle:
        "Stay connected with your clients with simple tools for sending branded marketing campaigns to every client who has paid an invoice or enrolled in your loyalty program.",
      blockImg: "marketing-outlined.png",
      blockList: [
        "Email marketing",
        "Promotions",
        "Facebook campaigns",
        "Customizable branding",
      ],
      forceReverse: false,
      ctaInfo: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/marketing",
      },
    },
  ],
};

export const accountantsCTA = {
  title: "Sign up for your consultation.",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/professional-services/accountants/demo",
  },
};

export const accountantsTestimonials = {
  title: "What other business owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const accountantsArticles = {
  title: "Expert advice on growing your \n" + "accounting business",
  bgText: "Articles.",
};

export const accountantsFaqs = {
  title: "Frequently Asked Questions",
  ctaInfo: {
    ctaTitle: "View more",
    ctaTarget: "/professional-services/accountants/demo",
  },
  list: [
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
  ],
};

export const accountantCar = {
  title: "Run a different type of professional business",
  subtext: "Accountants are just one of our specialties.",
  punctuation: "?",
  slides: [
    {
      bg: "plumber.png",
      cardTitle: "Plumber",
      cardContent: "Get paid fast, anytime, anywhere",
      icon: "plumber.png",
      linkTo: "/professional-services/plumbers",
    },
    {
      bg: "vet.png",
      cardTitle: "Veterinarians",
      cardContent: "Get paid fast, anytime, anywhere",
      icon: "vet.png",
      linkTo: "/professional-services/veterinarian",
    },
  ],
};
