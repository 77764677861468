import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import BHero from "../../../components/Hero/b-hero";
import {
  accountantsHero,
  accountantsSubHero,
  accountantsWhiteGlove,
  accountantsLargeFeatureA,
  accountantsTrusted,
  accountantsMultiSection,
  accountantsCTA,
  accountantsTestimonials,
  // accountantsArticles,
  // accountantsFaqs,
  accountantCar,
} from "../../../data/accountants-service-data";
import {
  minuteManPressSlides,
  heroSuccess,
} from "../../../data/success-stories-data";
// import NoVisualsCustom from "../../../components/NoVisuals/NoVisualsCustom";
// import WhiteGlove from "../../../components/WhiteGlove/WhiteGlove";
// import LargeFeatures from "../../../components/LargeFeatures/LargeFeatures";
// import TrustedNumbers from "../../../components/TrustedNumbers/TrustedNumbers";
// import LargeCta from "../../../components/CTAs/LargeCta";
// import SuccessStories from "../../../components/SuccessStories/SuccessStories";
// import TestmonialReviews from "../../../components/TestimonialReviews/TestmonialReviews";
// import BusinessTypes from "../../../components/BusinessTypes/BusinessTypes";

import heroBg from "../../../images/hero-images/accountants-hero.png";

const NoVisualsCustom = loadable(() =>
  import("../../../components/NoVisuals/NoVisualsCustom")
);
const WhiteGlove = loadable(() =>
  import("../../../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/LargeFeatures")
);
const TrustedNumbers = loadable(() =>
  import("../../../components/TrustedNumbers/TrustedNumbers")
);
const LargeCta = loadable(() => import("../../../components/CTAs/LargeCta"));
const SuccessStories = loadable(() =>
  import("../../../components/SuccessStories/SuccessStories")
);
const TestmonialReviews = loadable(() =>
  import("../../../components/TestimonialReviews/TestmonialReviews")
);
const BusinessTypes = loadable(() =>
  import("../../../components/BusinessTypes/BusinessTypes")
);

const whiteGloveImport = "accountants-white-glove.png";

const accountantsService = () => {
  return (
    <Layout>
      <SEO
        title="POS for Accounting Services| POS Accounting Software |  SpotOn"
        description="SpotOn's accounting POS offers integrated payments options, website, and loyalty tools to take your accounting business to the next level. Get a demo!"
        image={`https://spoton.com/${heroBg}`}
        cannonical="https://www.spoton.com/professional-services/accountants/"
      />
      <BHero sectionData={accountantsHero} heroBg="accountants-hero.png" />
      <NoVisualsCustom sectionData={accountantsSubHero} complex />
      <WhiteGlove
        sectionData={accountantsWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <LargeFeatures
        sectionData={accountantsLargeFeatureA}
        style={{ marginTop: "128px" }}
      />
      <TrustedNumbers
        title="By the numbers"
        numbersArray={accountantsTrusted}
      />
      <LargeFeatures
        sectionData={accountantsMultiSection}
        style={{ marginTop: "128px !important" }}
      />
      <LargeCta sectionData={accountantsCTA} />
      <SuccessStories sectionData={heroSuccess} slides={minuteManPressSlides} />
      <section style={{ marginTop: 40 }}>
        <TestmonialReviews sectionData={accountantsTestimonials} />
      </section>
      {/* <Articles
        sectionData={accountantsArticles}
        blogFilters={{
          limit: 3,
          include: 'tags,authors',
          filter: 'tag:professional-services',
        }}
        cornerStone={false}
        style={{ marginTop: 60 }}
      /> */}
      <BusinessTypes sectionData={accountantCar} />
      {/* <Faqs sectionData={accountantsFaqs} /> */}
    </Layout>
  );
};

export default accountantsService;
